import React from 'react'
import '../styles/project.scss'
import { Projects } from '../Data'
import { useParams } from 'react-router-dom'
import { FaArrowLeftLong } from 'react-icons/fa6'
import { Contact } from '../components'

const Project = () => {
  const { index } = useParams()
  const { companyImage,companyName,softwareDesc,softwareImage,projectPlan,overView,technologies } = Projects[index]
  return (
    <section className='project-page'>
      <div className='container'>
        <h2 className='top-heading TXT-heading3'>
          من أحد مشاريعنا 
        </h2>
        <article className='project-desc'>
          <aside className='text-side'>
            <div className='company'>
              <img src={companyImage} alt="" />
              {/* <h2 className='TXT-heading'>{companyName}</h2> */}
              <h2 className='TXT-heading'>{companyName}</h2>
            </div>
            {/* <p className='software-desc TXT-heading2'>
              {softwareDesc}
            </p> */}
            <p className='software-desc TXT-heading2'>
              {softwareDesc}
            </p>
            <a href="#section5">
              <button className='contact-btn TXT-heading3'> 
                  للتواصل 
                  <span className='icon TXT-heading2'>
                    {FaArrowLeftLong({})}
                  </span>
              </button>
            </a>
          </aside>
          <img className='project-image' src={softwareImage} alt="" />
        </article>
        <div className='project-details-holder'>
          <ul className='project-plan' role='list'>
            <h2 className='heading-txt TXT-heading'>
              خطة المشروع 
            </h2>
            {projectPlan.map((ele,ind)=><li key={ind} className='plan-li'>
              <div className='top'>
                <span className='icon'>
                  {ele.icon({})}
                </span>
                <p className='text TXT-heading2'>
                  {ele.name}
                </p>
              </div>
              <ul className='li-list'>
                {ele.fillTxt.map((e,i)=> <li className='TXT-normal' key={i}>{e}</li>)}
              </ul>
            </li>)
            }
          </ul>
          <div className="line"></div>
          <article className='overview-art'>
            <h2 className='TXT-heading'>
              نظرة عامة
            </h2>
            <p className='text TXT-normal'>
              {overView}
            </p>
          </article>
        </div>
        <h2 className='used-techno-heading TXT-heading'>
          التقنيات المستخدمة  
        </h2>
        <ul className='used-techno-list' role='list' dir='ltr'>
            {technologies.map((e,i)=><li className='technologies' key={i}>
                <h2 className='techno-type TXT-heading2'>
                    {e.technoName}
                </h2>
                {e.technos.map((e,i)=><img key={i} className='techno' src={e} alt="" />)}
            </li>)
            }
        </ul>
      </div>
      <Contact theme={'dark'} activeSection={[]} />
    </section>
  )
}

export default Project